export const TableTabMap = {
  WAIT: 0,
  PASS: 1,
  FAIL: 2
}

export const TableTabList = [
  { label: '待审核', value: 0 },
  { label: '通过', value: 1 },
  { label: '不通过', value: 2 }
]

// table中使用
export const CoursewareTypeMap = {
  ppt: 'PPT',
  plan: '教案',
  speak: '说课',
  audio: '音频',
  video: '视频',
  image: '图片',
  swf: 'flash动画',
  sheet_song: '曲谱',
  qgroup: '题目组'
}

export const AuditStatus = {
  PASS: 1,
  FAIL: 2
}

export const CoursewareType = {
  PPT: 'ppt',
  PLAN: 'plan',
  SPEAK: 'speak',
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  FLASH: 'swf',
  SHEET_SONG: 'sheet_song',
  QGROUP: 'qgroup'
}

export const CoursewareTypeOpts = [
  { label: 'PPT', value: 'ppt' },
  { label: '教案', value: 'plan' },
  { label: '说课', value: 'speak' },
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '图片', value: 'image' },
  { label: 'flash', value: 'swf' },
  { label: '曲谱', value: 'sheet_song' },
  { label: '题目组', value: 'qgroup' }
]

export const BookTypeMap = {
  OFFICIAL: 1
}
